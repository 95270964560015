import React from 'react'
import clsx from 'clsx'
import { variant as createVariant } from '../../util/index'
import Icon from './Icon'

export const VARIANT_DEFAULT = 'default'
export const VARIANT_UNPADDED = 'unpadded'
export const VARIANT_PADDED_SMALL = 'padded-small'

const CheckoutCardTitle = ({
    children,
    className,
    iconBeforeName,
}) => (
    <div className="flex">
        {iconBeforeName && (
            <Icon
                className="mr-2"
                name={iconBeforeName}
            />
        )}
        <h4
            className={clsx(
                'text-lg font-medium',
                className,
            )}
        >
            {children}
        </h4>
    </div>
)

const CheckoutCard = ({
    title,
    subtitle,
    children,
    className,
    variant = VARIANT_DEFAULT,
    ...props
}) => (
    <div
        className={clsx(
            'bg-white rounded shadow-md',
            createVariant(variant, {
                [VARIANT_UNPADDED]: 'p-0',
                [VARIANT_PADDED_SMALL]: 'px-2 lg:px-6 py-3 lg:py-5',
                [VARIANT_DEFAULT]: 'px-4 lg:px-12 py-7 lg:py-10',
            }),
            className
        )}
        {...props}
    >
        {(title || subtitle) && (
            <div className="mb-5">
                {title}
                {subtitle && (
                    <h5 className="text-sm text-gray-600 mt-2">
                        {subtitle}
                    </h5>
                )}
            </div>
        )}
        {children}
    </div>
)

CheckoutCard.Title = CheckoutCardTitle

export default CheckoutCard
