import useAppState from './useAppState'

export default function useAirportIataLocation() {
    const { destinations, airportCodes, destinationContinentCodes } = useAppState()

    return (airportIata) => {
        let destination
        const airport = airportCodes[airportIata]
        if (!airport) {
            destination = destinations.find((dest) => dest.value === airportIata)
            if (!destination) {
                return {
                    country: null,
                    destination: null,
                    continentCode: null,
                }
            }
        }

        destination = destination || destinations.find((dest) => dest.value === airport.cityCode)
        if (!destination) {
            return {
                country: null,
                destination: null,
                continentCode: null,
            }
        }

        const country = destinations.find((dest) => dest.value === destination.countryCode)
        return {
            country,
            destination,
            continent: country ? {
                value: country.continentCode,
                name: destinationContinentCodes[country.continentCode],
            } : null,
        }
    }
}
