/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, forwardRef } from 'react'
import clsx from 'clsx'
import { usePopper } from 'react-popper'
import { createPortal } from 'react-dom'
import { useOnClickOutside } from '@wappla/react-hooks'
import { combineRefs } from '../../util/index'

export const EVENT_CLICK = 'click'
export const EVENT_HOVER = 'hover'
export const EVENT_FOCUS = 'focus'
export const POSITION_AUTO = 'auto'
export const POSITION_AUTO_START = 'auto-start'
export const POSITION_AUTO_END = 'auto-end'
export const POSITION_TOP = 'top'
export const POSITION_TOP_START = 'top-start'
export const POSITION_TOP_END = 'top-end'
export const POSITION_BOTTOM = 'bottom'
export const POSITION_BOTTOM_START = 'bottom-start'
export const POSITION_BOTTOM_END = 'bottom-end'
export const POSITION_RIGHT = 'right'
export const POSITION_RIGHT_START = 'right-start'
export const POSITION_RIGHT_END = 'right-end'
export const POSITION_LEFT = 'left'
export const POSITION_LEFT_START = 'left-start'
export const POSITION_LEFT_END = 'left-end'

const PopoverContainer = forwardRef(({
    onOutsideClick,
    ...props
}, ref) => {
    const containerRef = useRef()
    useOnClickOutside(containerRef, () => {
        if (typeof onOutsideClick === 'function') {
            onOutsideClick()
        }
    })
    const refs = ref !== null ? [ref, containerRef] : [containerRef]
    return <div id="test" ref={combineRefs(refs)} {...props} />
})

const Popover = ({
    as = 'span',
    position = POSITION_BOTTOM_START,
    event = EVENT_CLICK,
    content,
    hasArrow,
    arrowClassName,
    className,
    children,
    target = '#popovers',
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [popperElement, setPopperElement] = useState(null)
    const [referenceElement, setReferenceElement] = useState(null)
    const [arrowElement, setArrowElement] = useState(null)
    let arrowModifiers = []
    if (hasArrow) {
        arrowModifiers = [{
            name: 'arrow',
            options: {
                element: arrowElement,
            },
        }, {
            name: 'offset',
            enabled: true,
            options: {
                offset: [0, 8]
            }
        }]
    }
    const { styles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
            strategy: 'fixed',
            placement: position,
            modifiers: [...arrowModifiers]
        },
    )
    const Trigger = as
    return (
        <>
            <Trigger
                className={clsx(
                    'focus:outline-none ',
                    className,
                )}
                ref={setReferenceElement}
                onClick={(e) => {
                    if (event === EVENT_CLICK) {
                        setIsOpen(!isOpen)
                        e.stopPropagation()
                    }
                }}
                onMouseEnter={() => event === EVENT_HOVER && setIsOpen(true)}
                onMouseLeave={() => event === EVENT_HOVER && setIsOpen(false)}
                onFocus={() => event === EVENT_FOCUS && setIsOpen(true)}
                onBlur={() => event === EVENT_FOCUS && setIsOpen(false)}
            >
                {children}
            </Trigger>
            {isOpen && createPortal(
                <PopoverContainer
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onOutsideClick={() => setIsOpen(false)}
                >
                    <div
                        ref={setPopperElement}
                        style={styles.popper}
                        className="z-popover"
                        {...attributes.popper}
                    >
                        {hasArrow && (
                            <div
                                ref={setArrowElement}
                                style={styles.arrow}
                                className={arrowClassName}
                            />
                        )}
                        {(() => {
                            if (typeof content === 'function') {
                                return content({
                                    open: () => setIsOpen(true),
                                    close: () => setIsOpen(false),
                                })
                            }
                            return content
                        })()}
                    </div>
                </PopoverContainer>,
                document.querySelector(target)
            )}
        </>
    )
}

Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_AUTO = POSITION_AUTO
Popover.POSITION_AUTO_START = POSITION_AUTO_START
Popover.POSITION_AUTO_END = POSITION_AUTO_END
Popover.POSITION_TOP = POSITION_TOP
Popover.POSITION_TOP_START = POSITION_TOP_START
Popover.POSITION_TOP_END = POSITION_TOP_END
Popover.POSITION_BOTTOM = POSITION_BOTTOM
Popover.POSITION_BOTTOM_START = POSITION_BOTTOM_START
Popover.POSITION_BOTTOM_END = POSITION_BOTTOM_END
Popover.POSITION_RIGHT = POSITION_RIGHT
Popover.POSITION_RIGHT_START = POSITION_RIGHT_START
Popover.POSITION_RIGHT_END = POSITION_RIGHT_END
Popover.POSITION_LEFT = POSITION_LEFT
Popover.POSITION_LEFT_START = POSITION_LEFT_START
Popover.POSITION_LEFT_END = POSITION_LEFT_END

export default Popover
