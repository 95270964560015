import * as constants from '@connections/constants'
import { getFlightMetaDataFromResultsUrl } from '@connections/utils'
import usePathnameLocale from '../../components/hooks/usePathnameLocale'
import useAirportIataLocation from '../../components/hooks/useAirportIataLocation'

const {
    INSURANCE_TYPES,
    CURRENCY_ROUNDING,
    BOOKING_ITEM_TYPE_FLIGHT: FLIGHT_ITEM,
} = constants

export function useFlightSearchMetaData() {
    const locale = usePathnameLocale()
    const getAirportIataLocation = useAirportIataLocation()

    const getLocationMetaDataFromAirportIata = (airportIata) => {
        const {
            country,
            continent,
            destination,
        } = getAirportIataLocation(airportIata)

        return {
            country: country?.name || '',
            city: destination?.name || '',
            continent: continent?.name || '',
        }
    }

    const flightSearchQueryToSearchStartMetaData = (resultsUrl) => {
        const {
            to,
            route,
            duration,
            fareType,
            language,
            flightMode,
            returnDate,
            bookingType,
            departureDate,
            numberOfAdults,
            bookingWindow,
            numberOfInfants,
            numberOfChildren,
            numberOfPassengers,
        } = getFlightMetaDataFromResultsUrl(resultsUrl, locale)
        const { city, country, continent } = getLocationMetaDataFromAirportIata(to)

        return {
            'search-route': route,
            'search-type': flightMode,
            'booking-type': bookingType,
            'search-faretype': fareType,
            'search-language': language,
            'search-duration': duration,
            'search-return-day': returnDate,
            'search-destination-city': city,
            'search-destination-area': continent,
            'search-departure-day': departureDate,
            'search-destination-country': country,
            'search-number-adults': numberOfAdults,
            'search-booking-window': bookingWindow,
            'search-number-infants': numberOfInfants,
            'search-number-children': numberOfChildren,
            'search-number-passengers': numberOfPassengers,
        }
    }

    const flightSearchQueryToSearchCompleteMetaData = (resultsUrl, responseTime, numberOfFlights, resultCountPerProvider) => {
        const {
            to,
            route,
            duration,
            fareType,
            language,
            flightMode,
            returnDate,
            bookingType,
            departureDate,
            numberOfAdults,
            bookingWindow,
            numberOfInfants,
            numberOfChildren,
            numberOfPassengers,
        } = getFlightMetaDataFromResultsUrl(resultsUrl, locale)
        const { city, country, continent } = getLocationMetaDataFromAirportIata(to)

        return {
            'search-route': route,
            'search-type': flightMode,
            ...resultCountPerProvider,
            'booking-type': bookingType,
            'search-faretype': fareType,
            'search-language': language,
            'search-duration': duration,
            'search-return-day': returnDate,
            'search-destination-city': city,
            'nr-flights-found': numberOfFlights,
            'search-response-time': responseTime,
            'search-destination-area': continent,
            'search-departure-day': departureDate,
            'search-destination-country': country,
            'search-number-adults': numberOfAdults,
            'search-booking-window': bookingWindow,
            'search-number-infants': numberOfInfants,
            'search-number-children': numberOfChildren,
            'search-number-passengers': numberOfPassengers,
        }
    }

    return {
        flightSearchQueryToSearchStartMetaData,
        flightSearchQueryToSearchCompleteMetaData,
    }
}

export function bookingMetaToSelectedMeta(bookingMeta) {
    return {
        'selected-price': bookingMeta.price,
        'selected-route': bookingMeta.route,
        'selected-stops': bookingMeta.stops,
        'booking-type': bookingMeta.bookingType,
        'selected-type': bookingMeta.journeyType,
        'selected-duration': bookingMeta.duration,
        'selected-language': bookingMeta.language,
        'selected-airline': bookingMeta.airlineIata,
        'selected-fareType': bookingMeta.cabinClass,
        'selected-destination-city': bookingMeta.city,
        'selected-return-day': bookingMeta.returnDate,
        'selected-booking-source': bookingMeta.source,
        'selected-adult-price': bookingMeta.adultPrice,
        'selected-child-price': bookingMeta.childPrice,
        'selected-infant-price': bookingMeta.infantPrice,
        'selected-has-packages': bookingMeta.hasPackages,
        'selected-airline-name': bookingMeta.airlineName,
        'selected-cabinClass': bookingMeta.cabinClassCode,
        'selected-destination-area': bookingMeta.continent,
        'selected-departure-day': bookingMeta.departureDate,
        'selected-destination-country': bookingMeta.country,
        'selected-booking-window': bookingMeta.bookingWindow,
        'selected-flight-numbers': bookingMeta.flightNumbers,
        'selected-number-adults': bookingMeta.numberOfAdults,
        'selected-number-infants': bookingMeta.numberOfInfants,
        'selected-number-children': bookingMeta.numberOfChildren,
        'selected-number-passengers': bookingMeta.numberOfPassengers,
    }
}

export function bookingMetaToPackagesMeta(bookingMeta) {
    return bookingMetaToSelectedMeta(bookingMeta)
}

export function bookingMetaToTravelersMeta(bookingMeta) {
    return {
        ...bookingMetaToSelectedMeta(bookingMeta),
        'selected-booker-city': bookingMeta.bookerCity,
        'selected-number-males': bookingMeta.numberOfMales,
        'selected-passenger-ages': bookingMeta.travelerAges,
        'selected-number-females': bookingMeta.numberOfFemales,
        'selected-booker-postal-code': bookingMeta.bookerPostalCode,
        'selected-insurances-total-price': bookingMeta.insurancesTotalPrice,
        ...INSURANCE_TYPES.reduce((acc, insuranceType) => {
            const accClone = { ...acc }
            accClone[`selected-number-${insuranceType}-insurance`] = bookingMeta[`numberOf${insuranceType}Insurance`]
            accClone[`selected-${insuranceType}-insurance-price`] = bookingMeta[`${insuranceType}InsurancePrice`]
            accClone[`selected-${insuranceType}-insurance-total-price`] = bookingMeta[`${insuranceType}InsuranceTotalPrice`]

            return accClone
        }, {})
    }
}

export function bookingMetaToPaymentSelectionMeta(bookingMeta) {
    return bookingMetaToTravelersMeta(bookingMeta)
}

export function bookingMetaToBookingConfirmedMeta(booking) {
    const { items, data: { metaData: bookingMeta, reservationCompleted } } = booking

    return {
        'booking-tax': bookingMeta.tax,
        'booking-route': bookingMeta.route,
        transactionTotal: booking.totalPrice,
        'booking-date': bookingMeta.bookingDate,
        'booking-type': bookingMeta.bookingType,
        'booking-duration': bookingMeta.duration,
        'booking-language': bookingMeta.language,
        'booking-total-price': booking.totalPrice,
        'booking-full-route': bookingMeta.fullRoute,
        'booking-destination-city': bookingMeta.city,
        'booking-booking-source': bookingMeta.source,
        'booking-is-low-cost': bookingMeta.isLowCost,
        'booking-return-day': bookingMeta.returnDate,
        'booking-booker-city': bookingMeta.bookerCity,
        transactionId: reservationCompleted?.PaxFileId,
        'booking-airline-code': bookingMeta.airlineIata,
        'booking-airline-name': bookingMeta.airlineName,
        'booking-number-males': bookingMeta.numberOfMales,
        'booking-fare-cabinclass': bookingMeta.cabinClass,
        'booking-payment-method': booking.payment.product,
        'booking-destination-area': bookingMeta.continent,
        'booking-passenger-ages': bookingMeta.travelerAges,
        'booking-departure-day': bookingMeta.departureDate,
        'booking-destination-country': bookingMeta.country,
        'booking-booking-window': bookingMeta.bookingWindow,
        'booking-flight-numbers': bookingMeta.flightNumbers,
        'booking-passengers': bookingMeta.numberOfPassengers,
        'booking-passengers-adt': bookingMeta.numberOfAdults,
        'booking-number-females': bookingMeta.numberOfFemales,
        'booking-passengers-inf': bookingMeta.numberOfInfants,
        'booking-passengers-cnn': bookingMeta.numberOfChildren,
        'booking-booker-postal-code': bookingMeta.bookerPostalCode,
        'booking-fare-cabinclass-code': bookingMeta.cabinClassCode,
        'booking-insurances-total-price': bookingMeta.insurancesTotalPrice,
        transactionProducts: items.map(({ data, type, productId }) => {
            const productItem = {
                quantity: 1,
                category: type,
                name: data.name || type,
                price: data.price / CURRENCY_ROUNDING,
            }
            switch (type) {
                case FLIGHT_ITEM:
                    return {
                        ...productItem,
                        sku: bookingMeta.route,
                        price: data.flight.charge.total,
                    }
                default:
                    return {
                        ...productItem,
                        sku: productId || type,
                        price: data.price / CURRENCY_ROUNDING,
                    }
            }
        }),
        ...INSURANCE_TYPES.reduce((acc, insuranceType) => {
            const accClone = { ...acc }
            accClone[`booking-number-${insuranceType}-insurance`] = bookingMeta[`numberOf${insuranceType}Insurance`]
            accClone[`booking-${insuranceType}-insurance-price`] = bookingMeta[`${insuranceType}InsurancePrice`]
            accClone[`booking-${insuranceType}-insurance-total-price`] = bookingMeta[`${insuranceType}InsuranceTotalPrice`]

            return accClone
        }, {})
    }
}
